import React, { Component } from 'react';

import radarLogo from '../../../assets/logo/logo.png';
import whitepaper from '../../../assets/docs/whitepaper.pdf';

import '../styles/footer.css';

class Footer extends Component {

    constructor(props) {
        super();
        this.toPage = this.toPage.bind(this);
    }

    toPage(path) {
        this.props.history.push(path);
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }

    render() {

        return (

            <div className="w-footer-wrapper">
                <div className="w-footer-content">
                    <div className="w-footer-logo-section">
                        <img src={radarLogo} alt='radar-logo' className="w-footer-logo" />
                        <div className="w-footer-motto">
                            One app. All chains. Every transaction.
                        </div>
                    </div>
                    <div className="w-footer-menu-section">
                        <div className="w-footer-menu-col">
                            <div className="w-footer-menu-title">
                                COMPANY
                            </div>
                            <a href="https://radar.blog/" target="_blank"
                                style={{textDecoration: 'none'}}
                            >
                                <div className="w-footer-menu-item">
                                    Blog
                                </div>
                            </a>
                            <a href="https://radar.global/resources" target="_blank"
                                style={{textDecoration: 'none'}}
                            >
                                <div className="w-footer-menu-item">
                                    Resources
                                </div>
                            </a>
                            <a href={whitepaper} target="_blank"
                                style={{textDecoration: 'none'}}
                            >
                                <div className="w-footer-menu-item">
                                    Whitepaper
                                </div>
                            </a>
                            <a href="https://radar.global/team" target="_blank"
                                style={{textDecoration: 'none'}}
                            >
                                <div className="w-footer-menu-item">
                                    Team
                                </div>
                            </a>
                            <a href="https://radar.global/terms-and-conditions" target="_blank"
                                style={{textDecoration: 'none'}}
                            >
                                <div className="w-footer-menu-item">
                                    Terms & Conditions
                                </div>
                            </a>
                        </div>
                        <div className="w-footer-menu-col">
                            <div className="w-footer-menu-title">
                                RADAR TOKEN
                            </div>
                            <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8" target="_blank"
                                style={{textDecoration: 'none'}}
                            >
                                <div className="w-footer-menu-item">
                                    Uniswap
                                </div>
                            </a>
                            <a href="https://pancakeswap.finance/swap?exactAmount=1&outputCurrency=0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8" target="_blank"
                                style={{textDecoration: 'none'}}
                            >
                                <div className="w-footer-menu-item">
                                    Pancakeswap
                                </div>
                            </a>
                            <a href="https://coinmarketcap.com/currencies/radar/" target="_blank"
                                style={{textDecoration: 'none'}}
                            >
                                <div className="w-footer-menu-item">
                                    Coinmarketcap
                                </div>
                            </a>
                            <a href="https://www.coingecko.com/en/coins/bot-ocean" target="_blank"
                                style={{textDecoration: 'none'}}
                            >
                                <div className="w-footer-menu-item">
                                    Coingecko
                                </div>
                            </a>
                            <a href="https://www.dextools.io/app/ether/pair-explorer/0x71000582ec4914629a61ec95f22f764aa7e3b8a5" target="_blank"
                                style={{textDecoration: 'none'}}
                            >
                                <div className="w-footer-menu-item">
                                    Dextools
                                </div>
                            </a>
                            <a href="https://etherscan.io/token/0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8" target="_blank"
                                style={{textDecoration: 'none'}}
                            >
                                <div className="w-footer-menu-item">
                                    Etherscan
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;
