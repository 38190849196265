import React, { Component } from 'react';

import linkIcon from '../../../../assets/icons/link-icon.png';

import '../../styles/products.css';

class ProductCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: this.props.titleFromParent,
            imgSrc: this.props.imgSrcFromParent,
            text: this.props.textFromParent,
            link: this.props.linkFromParent,
        }
    }

    render() {

        const smallerWidthStyle = {
            width: '80%'
        }

        return (

            <>
                <div className="w-product-card">
                    <div className="w-product-card-logo-section">
                        <img 
                            src={this.state.imgSrc}
                            alt="product-card-logo"
                            className="w-product-card-logo"
                            style={this.state.title === "Radar Wallet" ? smallerWidthStyle : {}}
                        />
                    </div>
                    <div className="w-product-card-text">
                        {this.state.text}
                    </div>
                    <a 
                        // href={this.state.link} 
                        // target="_blank" 
                        style={{textDecoration: 'none'}}
                    >
                        <div className="w-product-card-text-link-section">
                            <div className="w-product-card-text-link">
                                <span>
                                    Launch app
                                </span>
                            </div>
                            <img
                                src={linkIcon}
                                alt="product-card-link-icon"
                                className="w-product-card-link-icon"
                            />
                        </div>
                    </a>
                </div>
            </>
        )
    }
}

export default ProductCard;