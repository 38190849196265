import React, { Component } from 'react';
import { ethers } from 'ethers';

import { abi as StakingRadarABI } from '../../../assets/ABIs/StakingRadar.json';
import configs from './../../../configs';

import closeIcon from '../../../assets/icons/close-icon.png';
import tokenIcon from '../../../assets/logo/token-icon.png';

import '../styles/popup.css';
import '../../../styles/index.css';

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class GetRewardsPopup extends Component {

    constructor(props){
        super(props);

        this.state = {
            ...this.props.state,

            popup: false,

            yourReward: '...',

            loading: false,
        }
    }

    closePopup = () => {
        this.setState({ popup: false });
        this.props.closePopupEvent();
    }

    componentDidMount() {
        this.getRewardAmount();
    }

    getRewardAmount = async () => {

        var rewardsAccumulated = '...';
        try {
            const provider = new ethers.providers.Web3Provider(this.state.provider);

            // Interface
            const StakingRadarInterface = new ethers.utils.Interface(
                JSON.parse(JSON.stringify(StakingRadarABI))
            );
            const stakingRadar = new ethers.Contract(
                configs.CONTRACT_ADDRESSES.StakingRadar,
                StakingRadarInterface,
                provider
              );
            
            var earned = await stakingRadar.earned(this.state.address);

            rewardsAccumulated = Math.floor(earned / 10**18);
        } catch(e) {
            console.log(e)
        }

        this.setState({
            yourReward: rewardsAccumulated
        })
    }

    claimReward = async (e) => {
        e.preventDefault();

        this.setState({
            loading: true
        })

        try {
            const provider = new ethers.providers.Web3Provider(this.state.provider);

            const signer = await provider.getSigner();

            // Interface
            const StakingRadarInterface = new ethers.utils.Interface(
                JSON.parse(JSON.stringify(StakingRadarABI))
            );
            const stakingRadar = new ethers.Contract(
                configs.CONTRACT_ADDRESSES.StakingRadar,
                StakingRadarInterface,
                signer
            );

            await stakingRadar.getReward();
        } catch (e) {
            console.log(e)
        }
        
        await this.setState({
            loading: false
        })
        this.props.callbackPopups();
    }

    renderLoading() {

        return (

            <>
                <div className="w-loading-wrapper">
                    <div className="w-loading-content">
                    <Loader
                        type="Puff"
                        color="#E926C3"
                        height={200}
                        width={200}
                        timeout={1000000} //3 secs
                    />
                    </div>
                </div>
            </>
        )
    }

    render() {

        return (

            <>
                <div className="popup-wrapper">
                    <div className="popup-box">
                        <div className="popup-header">
                            <div className="popup-header-text">
                                CLAIM REWARD
                            </div>
                            <div className="popup-header-close-icon-section">
                                <img
                                    onClick={() => this.closePopup()}
                                    src={closeIcon}
                                    alt='close-icon'
                                    className="popup-header-close-icon"
                                />
                            </div>
                        </div>
                        <div className="popup-get-rewards-box">
                            <div className="popup-get-rewards-balance-section">
                                <img
                                    src={tokenIcon}
                                    alt='radar-token-icon'
                                    className="popup-get-rewards-token-icon"
                                />
                                <div className="popup-get-rewards-balance">
                                    <div className="popup-get-rewards-balance-info">
                                        Your reward
                                    </div>
                                    <div className="popup-get-rewards-balance-value">
                                        {this.state.yourReward} RADAR
                                    </div>
                                </div>
                            </div>
                            <div className="popup-get-rewards-button"
                                onClick={(e) => this.claimReward(e)}
                            >
                                <div className="popup-get-rewards-button-text">
                                    CLAIM REWARD
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.loading === true && this.renderLoading()}
            </>
        )
    }

}

export default GetRewardsPopup;