const configs = {
    DEBUG_MODE: false,
    networkId_DEBUG: 31337,
    networkId: 1,
    // networkId: 31337,
    // FALLBACK_PROVIDER: "http://192.168.1.63:8545",
    FALLBACK_PROVIDER: "https://eth-mainnet.alchemyapi.io/v2/BMl6OKMeu0A6ZnM8gj5oBrzdO63r5SXH",
    FALLBACK_PROVIDER_DEBUG: "http://localhost:8545",
    CONTRACT_ADDRESSES: {
        "StakingRadar": "0x84F3e2d0B1C026c7a368DbAA45Ba84CFc1a24aFb",
        "RADAR": "0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8"
    }
}

module.exports = configs;