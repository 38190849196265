import React, { Component } from 'react';
import { ethers } from 'ethers';

import { abi as StakingRadarABI } from '../../../assets/ABIs/StakingRadar.json';
import configs from './../../../configs';

import icon from '../../../assets/icons/your-rewards-card-icon.png';

import '../styles/cards.css';

class yourRewardCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.state,

            yourReward: ''
        }
    }

    componentDidUpdate(prevPreps) {
        if (this.props.state.walletConnected != this.state.walletConnected ||
            this.props.state.address != this.state.address ||
            this.props.state.balance != this.state.balance ||
            this.props.state.onboard != this.state.onboard) {
            this.setState({
                ...this.props.state
            });
            this.getRewardAmount()
        }
    }

    componentDidMount() {
        this.getRewardAmount();
    }

    getRewardAmount = async () => {

        if (this.state.walletConnected === false) {
            
            this.setState({
                yourReward: '-'
            })
        } else {
            var rewardsAccumulated = '...'

            try {
                const provider = new ethers.providers.Web3Provider(this.state.provider);
    
                // Interface
                const StakingRadarInterface = new ethers.utils.Interface(
                    JSON.parse(JSON.stringify(StakingRadarABI))
                );
                const stakingRadar = new ethers.Contract(
                    configs.CONTRACT_ADDRESSES.StakingRadar,
                    StakingRadarInterface,
                    provider
                  );
                
                var earned = await stakingRadar.earned(this.state.address);
    
                rewardsAccumulated = Math.floor(earned / 10**18);
            } catch(e) {
                console.log(e)
            }
    
            this.setState({
                yourReward: rewardsAccumulated
            })
        }        
    }

    render() {

        return (

            <>
                <div className="wm-card">
                    <div className="card-content">
                        <div className="card-icon-section">
                            <img 
                                src={icon}
                                alt='your-rewards-card-icon'
                                className="card-icon"
                            />
                        </div>
                        <div className="card-info-section">
                            <div className="card-info-primary-text">
                                {this.state.yourReward}
                            </div>
                            <div className="card-info-secondary-text">
                                Your rewards (RADAR)
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default yourRewardCard;
