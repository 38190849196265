import configs from './../configs';
import Onboard from 'bnc-onboard'

const netId = () => {
  console.log(`NETID: ${configs.DEBUG_MODE ? configs.networkId_DEBUG : configs.networkId}`)
    return configs.DEBUG_MODE ? configs.networkId_DEBUG : configs.networkId;
}

export function initOnboard(subscriptions) {
  const onboard = Onboard;
  
  const rpcUrl = configs.DEBUG_MODE ? configs.FALLBACK_PROVIDER_DEBUG : configs.FALLBACK_PROVIDER;
  return onboard({
  //   dappId,
    hideBranding: false,
    networkId: netId(),
  //   apiUrl,
    darkMode: true,
    subscriptions,
    // subscriptions: {
    //     wallet: onboardUpdateWallet,
    //     address: onboardUpdateAddress,
    //     balance: onboardUpdateBalance
    // },
    walletSelect: {
      wallets: [
        { walletName: 'metamask', preferred: true },
        {
          walletName: 'trezor',
          appUrl: 'https://radarprotocol.com',
          email: 'contact@radar.global',
          rpcUrl,
          preferred: true
        },
        {
          walletName: 'ledger',
          rpcUrl,
          preferred: true
        },
        {
          walletName: 'walletConnect',
          rpc: {
              [42]: configs.FALLBACK_PROVIDER_DEBUG,
              [1]: configs.FALLBACK_PROVIDER,
              [31337]: configs.FALLBACK_PROVIDER_DEBUG
          },
          preferred: true
        },
        { walletName: 'coinbase', preferred: true, rpcUrl },
        { walletName: "gnosis" },
      //   { walletName: 'cobovault', appName: 'React Demo', rpcUrl },
      //   { walletName: 'keystone', appName: 'React Demo', rpcUrl },
      //   { walletName: 'keepkey', rpcUrl },
      //   {
      //     walletName: 'lattice',
      //     appName: 'Onboard Demo',
      //     rpcUrl
      //   },
      //   { walletName: 'status' },
      //   { walletName: 'walletLink', rpcUrl },
      //   {
      //     walletName: 'portis',
      //     apiKey: 'b2b7586f-2b1e-4c30-a7fb-c2d1533b153b'
      //   },
        { walletName: 'fortmatic', apiKey: 'pk_test_886ADCAB855632AA' },
      //   { walletName: 'torus' },
      //   { walletName: 'trust', rpcUrl },
      //   { walletName: 'opera' },
      //   { walletName: 'operaTouch' },
      //   { walletName: 'imToken', rpcUrl },
      //   { walletName: 'meetone' },
      //   { walletName: 'mykey', rpcUrl },
      //   { walletName: 'wallet.io', rpcUrl },
        { walletName: 'huobiwallet', rpcUrl, preferred: true },
      //   { walletName: 'alphawallet', rpcUrl },
      //   { walletName: 'hyperpay' },
      //   { walletName: 'atoken' },
      //   { walletName: 'liquality' },
      //   { walletName: 'frame' },
      //   { walletName: 'tokenpocket', rpcUrl },
      //   { walletName: 'authereum', disableNotifications: true },
      //   { walletName: 'ownbit' },
      //   { walletName: 'gnosis' },
      //   { walletName: 'dcent' },
      //   { walletName: 'bitpie' },
      //   { walletName: 'xdefi' },
      //   { walletName: 'binance' },
      //   { walletName: 'tp' },
      ]
    },
    walletCheck: [
      { checkName: 'derivationPath' },
      { checkName: 'connect' },
      { checkName: 'accounts' },
      { checkName: 'network' },
      // { checkName: 'balance', minimumBalance: '100000' }
    ]
  })
}