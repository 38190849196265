import React, { Component } from 'react';
import { ethers } from 'ethers';

import { abi as StakingRadarABI } from '../../../assets/ABIs/StakingRadar.json';
import configs from './../../../configs';
import { BigNumber } from 'bignumber.js';

import closeIcon from '../../../assets/icons/close-icon.png';

import '../styles/popup.css';
import '../../../styles/index.css';

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class WithdrawPopup extends Component {

    constructor(props) {
        super(props);

        this.state ={
            ...this.props.state,

            popup: false,

            value: '0',
            maxValue: '...',
            maxValueAlert: false,
            maxValueSelected: false,
            maxValueUnscaled: 0,

            loading: false ,
        }

        this.withdraw = this.withdraw.bind(this);
    }

    closePopup = () => {
        this.setState({ popup: false });
        this.props.closePopupEvent();
    }

    async componentDidMount() {
        await this.getStakedRadar();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.state.address != this.state.address || 
            this.props.state.provider != this.state.provider) {
            await this.setState({
                address: this.props.state.address,
                provider: this.props.state.provider
            })
            this.getStakedRadar();
        }
    }

    getStakedRadar = async () => {
        var stakedBalance = '...'

        try {
            const provider = new ethers.providers.Web3Provider(this.state.provider);

            // Interface
            const StakingRadarInterface = new ethers.utils.Interface(
                JSON.parse(JSON.stringify(StakingRadarABI))
            );
            const stakingRadar = new ethers.Contract(
                configs.CONTRACT_ADDRESSES.StakingRadar,
                StakingRadarInterface,
                provider
              );
            
            var balance = await stakingRadar.balanceOf(this.state.address);
            var maxValueUnscaled = balance;

            stakedBalance = Math.floor(balance / 10**18);
        } catch(e) {
            console.log(e)
        }

        this.setState({
            maxValue: stakedBalance,
            maxValueUnscaled: maxValueUnscaled
        })      
    }

    toFixed(x) {
        if (Math.abs(x) < 1.0) {
          var e = parseInt(x.toString().split('e-')[1]);
          if (e) {
              x *= Math.pow(10,e-1);
              x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
          }
        } else {
          var e = parseInt(x.toString().split('+')[1]);
          if (e > 20) {
              e -= 20;
              x /= Math.pow(10,e);
              x += (new Array(e+1)).join('0');
          }
        }
        return x;
      }

    withdraw = async (e) => {

        this.setState({
            loading: true
        })
        
        try {
            const provider = new ethers.providers.Web3Provider(this.state.provider);

            const signer = await provider.getSigner();

            // Interface
            const StakingRadarInterface = new ethers.utils.Interface(
                JSON.parse(JSON.stringify(StakingRadarABI))
            );
            const StakingRadar = new ethers.Contract(
                configs.CONTRACT_ADDRESSES.StakingRadar,
                StakingRadarInterface,
                signer
            );

            var withdrawAmount = this.state.maxValueSelected ? this.state.maxValueUnscaled : this.toFixed(new BigNumber(this.state.value).multipliedBy(10**18).toString());

            await StakingRadar.withdraw(withdrawAmount);
        
        } catch (e) {
            console.log(e)
        }

        await this.setState({
            loading: false
        })
        this.props.callbackPopups();
    }

    inputField = (e) => {

        this.setState({
            maxValueSelected: false
        })

        if (e.target.value == '') {
            this.setState({ value: '0' });
            return;
        }

        if (e.target.value > this.state.maxValue) {
            this.setState({ maxValueAlert: true });
            return;
        } else {
            this.setState({ maxValueAlert: false })
        }

        const re = /^[0-9.\b]+$/;
        if (!re.test(e.target.value)) {
            return;
        }

        var value = e.target.value;
        value = parseInt(value).toString();
        this.setState({ value: value });
    }

    renderMaxValueAlert() {
        
        return (

            <>
                <div className="popup-wrapper-amount-alert">
                    You have exceeded the amount of tokens in your wallet.
                </div>
            </>
        )
    }

    renderLoading() {

        return (

            <>
                <div className="w-loading-wrapper">
                    <div className="w-loading-content">
                    <Loader
                        type="Puff"
                        color="#E926C3"
                        height={200}
                        width={200}
                        timeout={1000000} //3 secs
                    />
                    </div>
                </div>
            </>
        )
    }

    render() {

        return (

            <>
                <div className="popup-wrapper">
                    <div className="popup-box">
                        <div className="popup-header">
                            <div className="popup-header-text">
                                WITHDRAW
                            </div>
                            <div className="popup-header-close-icon-section">
                                <img
                                    onClick={() => this.closePopup()}
                                    src={closeIcon}
                                    alt='close-icon'
                                    className="popup-header-close-icon"
                                />
                            </div>
                        </div>
                        <div className="popup-input-section">
                            <div className="popup-input-info">
                                RADAR Amount
                            </div>
                            <div className="popup-input">
                                <input type="text" id="amount" name="amount"
                                    value={this.state.value} onChange={(e) => this.inputField(e)}
                                    style={{
                                        width: '50%',
                                        borderWidth: '0',
                                        backgroundColor: '#070708',
                                        color: '#999',
                                        fontFamily: 'Montserrat, sans-serif',
                                        fontSize: '15px',
                                        fontWeight: '400',
                                        outline: 'none',
                                        textAlign: 'left'
                                    }}
                                />
                                <div className="popup-input-max-amount-bullet"
                                    onClick={() => {
                                        if (this.state.maxValue !== '...')
                                            this.setState({
                                                value: this.state.maxValue,
                                                maxValueAlert: false
                                            })
                                    }}
                                >
                                    <div className="popup-input-max-amount">
                                        Max: {this.state.maxValue} RADAR
                                    </div>
                                </div>
                            </div>
                            {this.state.maxValueAlert === true && this.renderMaxValueAlert()}
                        </div>
                        <div className="popup-button withdraw"
                            onClick={() => this.withdraw()}
                        >
                            <div className="popup-button-text">
                                WITHDRAW
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.loading === true && this.renderLoading()}
            </>
        )
    }
}

export default WithdrawPopup;