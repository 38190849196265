import React, { Component } from 'react';

import twitterIcon from '../../../assets/icons/twitter-icon.svg';
import mediumIcon from '../../../assets/icons/medium-icon.svg';
import telegramIcon from '../../../assets/icons/telegram-icon.svg';
import emailIcon from '../../../assets/icons/email-icon.svg';

import '../styles/footer.css';

class AllRightsReserved extends Component {

    render() {

        const Mailto = ({ email, subject = '', body = '', children }) => {
            let params = subject || body ? '?' : '';
            if (subject) params += `subject=${encodeURIComponent(subject)}`;
            if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
          
            return <a href={`mailto:${email}${params}`}>{children}</a>;
        };

        return (

            <div className="w-arr-wrapper">
                <div className="w-arr-content">
                    <div className="w-arr-text">
                        © 2021 Radar. All rights reserved
                    </div>
                    <div className="w-arr-socials-section">
                        <a href="https://twitter.com/radarprotocol" target="_blank">
                            <img src={twitterIcon} alt='twitter-icon' className="w-arr-social-icon first" />
                        </a>
                        <a href="https://medium.com/@radarprotocol" target="_blank">
                            <img src={mediumIcon} alt='medium-icon' className="w-arr-social-icon" />
                        </a>
                        <a href="https://t.me/radarprotocol" target="_blank">
                            <img src={telegramIcon} alt='telegram-icon' className="w-arr-social-icon" />
                        </a>
                        <Mailto email="contact@radar.global" subject="" body="">
                            <img src={emailIcon} alt='email-icon' className="w-arr-social-icon last" />
                        </Mailto>
                    </div>
                </div>
            </div>
        )
    }
}

export default AllRightsReserved;
