import React, { Component } from 'react';

import { initOnboard } from './../ethereum/onboard';

import Header from '../components/header/_web/Header';
import Cards from '../components/cards/_web/Cards';
import Products from '../components/products/_web/Products';
import Footer from '../components/footer/_web/Footer';
import AllRightsReserved from '../components/footer/_web/AllRightsReserved';

import DepositPopup from '../components/popups/deposit-popup/DepositPopup';
import GetRewardsPopup from '../components/popups/get-rewards-popup/GetRewardsPopup';
import WithdrawPopup from '../components/popups/withdraw-popup/WithdrawPopup';

import MobileCards from '../components/cards/_mobile/MobileCards';
import MobileProducts from '../components/products/_mobile/MobileProducts';

import '../styles/index.css';
import './../onboardStaking.css';

class HomePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            depositPopup: false,
            withdrawPopup: false,
            getRewardsPopup: false,
            onboard: null,
            provider: null,
            address: "",
            walletConnected: false,
            balance: '0',
            wallet: null,
            remountKey: 0
        }

        this.onboardUpdateCallback = this.onboardUpdateCallback.bind(this);
        this.doUpdateBalance = this.doUpdateBalance.bind(this);
        this.doUpdateAddress = this.doUpdateAddress.bind(this);
        this.doUpdateWallet = this.doUpdateWallet.bind(this);
        this.setWalletConnected = this.setWalletConnected.bind(this);
        this.callbackPopups = this.callbackPopups.bind(this);
    }

    componentDidMount() {
        this.setState({
            onboard: initOnboard({
                balance: this.doUpdateBalance,
                address: this.doUpdateAddress,
                wallet: this.doUpdateWallet
            })
        });
    }

    async callbackPopups() {
        await this.setState({
            depositPopup: false,
            withdrawPopup: false,
            getRewardsPopup: false,
            remountKey: (this.state.remountKey+1)
        })
    }

    async setWalletConnected(status) {
        await this.setState({
            walletConnected: status
        })
    }

    doUpdateWallet(w) {
        console.log("UPDATE WALLET: "+w)
        if (this.state.walletConnected)
            this.setState({
                wallet: w,
                provider: typeof(w.provider) === "undefined" ? null : w.provider,
                walletConnected: typeof(w.provider) !== "undefined"
            })
    }

    doUpdateBalance(b) {
        if (this.state.walletConnected)
            this.setState({
                balance: b
            });
    }

    doUpdateAddress(a) {
        if (this.state.walletConnected)
            this.setState({
                address: a
            })
    }

    onboardUpdateCallback(state) {
        console.log("Setting walletConnected: "+state.walletConnected)
        this.setState({...state});
    }

    closeDepositPopup = () => {
        this.setState({
            depositPopup: false,
        })
    }

    closeWithdrawPopup = () => {
        this.setState({
            withdrawPopup: false,
        })
    }

    closeGetRewardsPopup = () => {
        this.setState({
            getRewardsPopup: false,
        })
    }

    renderDepositPopup() {

        return (

            <>
                <DepositPopup {...this.props} 
                    connectCallback={this.onboardUpdateCallback} 
                    state={this.state}
                    closePopupEvent={this.closeDepositPopup}
                    callbackPopups={this.callbackPopups}
                />
            </>
        )
    }

    renderWithdrawPopup() {

        return (

            <>
                <WithdrawPopup {...this.props}
                    connectCallback={this.onboardUpdateCallback} 
                    state={this.state} {...this.props} 
                    closePopupEvent={this.closeWithdrawPopup}
                    callbackPopups={this.callbackPopups}
                />
            </>
        )
    }

    renderGetRewardsPopup() {

        return (

            <>
                <GetRewardsPopup {...this.props}
                    connectCallback={this.onboardUpdateCallback} 
                    state={this.state} {...this.props} 
                    closePopupEvent={this.closeGetRewardsPopup}
                    callbackPopups={this.callbackPopups}
                />
            </>
        )
    }

    displayDepositPopup = () => {
        if (this.state.walletConnected === true) {
            this.setState({
                depositPopup: true,
                withdrawPopup: false,
                getRewardsPopup: false,
            })
        } else {
            return
        }
    }

    displayWithdrawPopup = () => {
        if (this.state.walletConnected === true) {
            this.setState({
                depositPopup: false,
                withdrawPopup: true,
                getRewardsPopup: false,
            })
        } else {
            return
        }
    }

    displayGetRewardPopup = () => {
        if (this.state.walletConnected === true) {
            this.setState({
                depositPopup: false,
                withdrawPopup: false,
                getRewardsPopup: true,
            })
        } else {
            return
        }
    }

    render() {

        const disabledButtonStyle = {
            cursor: 'not-allowed'
        }
        
        var width = window.innerWidth;

        if (width > 1000) { // WEB VERSION
            
            return (

                <>
                    <div className="w-wrapper">
                        <Header 
                            connectCallback={this.onboardUpdateCallback}
                            updateConnectedStatusCallback={this.setWalletConnected}
                            state={this.state} {...this.props} 
                        />
                        <div className="w-buttons-wrapper">
                            <div className="w-buttons-content">
                                <div className="w-buttons-header">
                                    STAKING
                                </div>
                                <div className="w-buttons-section">
                                    <div className="w-deposit-button"
                                        style={this.state.walletConnected === false ? disabledButtonStyle : {}}
                                        onClick={() => this.displayDepositPopup()}
                                    >
                                        <div className="w-deposit-button-text">
                                            DEPOSIT
                                        </div>
                                    </div>
                                    <div className="w-withdraw-button"
                                        style={this.state.walletConnected === false ? disabledButtonStyle : {}}
                                        onClick={() => this.displayWithdrawPopup()}
                                    >
                                        <div className="w-withdraw-button-text">
                                            WITHDRAW
                                        </div>
                                    </div>
                                    <div className="w-get-rewards-button"
                                        style={this.state.walletConnected === false ? disabledButtonStyle : {}}
                                        onClick={() => this.displayGetRewardPopup()}
                                    >
                                        <div className="w-get-rewards-button-text">
                                            CLAIM REWARD
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Cards remountKey={this.state.remountKey} {...this.props} 
                            connectCallback={this.onboardUpdateCallback} 
                            updateConnectedStatusCallback={this.setWalletConnected}
                            state={this.state} {...this.props} 
                        />
                        <Products {...this.props} />
                        <Footer {...this.props} />
                        <AllRightsReserved {...this.props} />
                        {this.state.depositPopup === true && this.renderDepositPopup()}
                        {this.state.getRewardsPopup === true && this.renderGetRewardsPopup()}
                        {this.state.withdrawPopup === true && this.renderWithdrawPopup()}
                    </div>
                </>
            )
        } else { // MOBILE VERSION

            return (

                <>
                    <div className="m-wrapper">
                        <Header
                            updateConnectedStatusCallback={this.setWalletConnected}
                            connectCallback={this.onboardUpdateCallback} 
                            state={this.state} {...this.props} 
                        />
                        <div className="m-button-wrapper">
                            <div className="m-button-content">
                                <div className="m-button-header">
                                    STAKING
                                </div>
                                <div className="m-claim-reward-button"
                                    style={this.state.walletConnected === false ? disabledButtonStyle : {}}
                                    onClick={() => this.displayGetRewardPopup()}
                                >
                                    <div className="m-claim-reward-button-text">
                                        CLAIM REWARD
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MobileCards {...this.props} 
                            connectCallback={this.onboardUpdateCallback} 
                            state={this.state} {...this.props} 
                        />
                        <MobileProducts {...this.props} />
                    </div>
                    <div className="m-buttons-wrapper">
                            <div className="m-buttons-content">
                                <div className="m-deposit-button"
                                    style={this.state.walletConnected === false ? disabledButtonStyle : {}}
                                    onClick={() => this.displayDepositPopup()}
                                >
                                    <div className="m-deposit-button-text">
                                        DEPOSIT
                                    </div>
                                </div>
                                <div className="m-withdraw-button"
                                    style={this.state.walletConnected === false ? disabledButtonStyle : {}}
                                    onClick={() => this.displayWithdrawPopup()}
                                >
                                    <div className="m-withdraw-button-text">
                                        WITHDRAW
                                    </div>
                                </div>
                            </div>
                        </div>
                    {this.state.depositPopup === true && this.renderDepositPopup()}
                    {this.state.getRewardsPopup === true && this.renderGetRewardsPopup()}
                    {this.state.withdrawPopup === true && this.renderWithdrawPopup()}
                </>
            )
        }
    }
}

export default HomePage;
