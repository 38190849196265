import React, { Component } from 'react';
import { ethers } from 'ethers';

import { abi as StakingRadarABI } from '../../../assets/ABIs/StakingRadar.json';
import configs from './../../../configs';

import icon from '../../../assets/icons/staked-bots-card-icon.png';

import '../styles/cards.css';

class StakedRadarCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.state,

            stakedRadar: '',
        }
    }

    componentDidUpdate(prevPreps) {
        if (this.props.state.walletConnected != this.state.walletConnected ||
            this.props.state.address != this.state.address ||
            this.props.state.balance != this.state.balance ||
            this.props.state.onboard != this.state.onboard) {
            this.setState({
                ...this.props.state
            });
            this.getStakedRadar()
        }
    }

    componentDidMount() {
        this.getStakedRadar();
    }

    getStakedRadar = async () => {

        if (this.state.walletConnected === false) {
            
            this.setState({
                stakedRadar: '-'
            })
        } else {
            var stakedBalance = '...'

            try {
                const provider = new ethers.providers.Web3Provider(this.state.provider);
    
                // Interface
                const StakingRadarInterface = new ethers.utils.Interface(
                    JSON.parse(JSON.stringify(StakingRadarABI))
                );
                const stakingRadar = new ethers.Contract(
                    configs.CONTRACT_ADDRESSES.StakingRadar,
                    StakingRadarInterface,
                    provider
                  );
                
                var balance = await stakingRadar.balanceOf(this.state.address);
    
                stakedBalance = Math.floor(balance / 10**18);
            } catch(e) {
                console.log(e)
            }
    
            this.setState({
                stakedRadar: stakedBalance
            })
        }        
    }

    render() {

        return (

            <>
                <div className="wm-card">
                    <div className="card-content">
                        <div className="card-icon-section">
                            <img 
                                src={icon}
                                alt='staked-bots-card-icon'
                                className="card-icon"
                            />
                        </div>
                        <div className="card-info-section">
                            <div className="card-info-primary-text">
                                {this.state.stakedRadar}
                            </div>
                            <div className="card-info-secondary-text">
                                RADAR staked
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default StakedRadarCard;
