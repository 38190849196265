import React, { Component } from 'react';

import closeIcon from '../../../assets/icons/close-icon.png';
import greyCheckboxIcon from '../../../assets/icons/grey-checkbox-icon.png';
import redCheckboxIcon from '../../../assets/icons/red-checkbox-icon.png';
import pinkCheckboxIcon from '../../../assets/icons/pink-checkbox-icon.png';

import { abi as IERC20ABI } from './../../../assets/ABIs/IERC20.json';
import { abi as StakingRadarABI } from './../../../assets/ABIs/StakingRadar.json';
import { ethers } from 'ethers'
import configs from './../../../configs';

import { BigNumber } from 'bignumber.js';

import '../styles/popup.css';
import '../../../styles/index.css';

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class DepositPopup extends Component {

    constructor(props) {
        super(props);

        this.state ={
            ...this.props.state,

            popup: false,
            
            value: 0,
            maxValue: 0,
            maxValueSelected: false,
            maxValueUnscaled: 0,
            maxValueAlert: false,
            allowance: 0,

            terms: false,
            approved: false,
            buttonClicked: false,

            loading: false,
        }

        this.getAllowance = this.getAllowance.bind(this);
        this.approve = this.approve.bind(this);
        this.deposit = this.deposit.bind(this);
    }

    async componentDidMount() {
        await this.getAllowance();
        await this.getRadarBalance();
        console.log("ALLWOANCE: "+this.state.allowance)
    }

    closePopup = () => {
        this.setState({ popup: false });
        this.props.closePopupEvent();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.state.address != this.state.address || 
            this.props.state.provider != this.state.provider) {
            await this.setState({
                address: this.props.state.address,
                provider: this.props.state.provider
            })
            console.log("Updated provider: "+this.state.provider)
            this.getAllowance();
            this.getRadarBalance();
        }
    }

    async getAllowance() {
        try {
            const provider = new ethers.providers.JsonRpcProvider(configs.FALLBACK_PROVIDER);

            const RadarInterface = new ethers.utils.Interface(
                JSON.parse(JSON.stringify(IERC20ABI))
            );

            const Radar = new ethers.Contract(
                configs.CONTRACT_ADDRESSES.RADAR,
                RadarInterface,
                provider
            )

            const allowance = await Radar.allowance(this.state.address, configs.CONTRACT_ADDRESSES.StakingRadar);

            // return parseInt(allowance) < parseInt(this.state.value) * 10**18
            await this.setState({
                allowance: allowance
            })
        } catch(e) {
            console.log(e);
        }
    }

    getRadarBalance = async () => {

        try {
            
            var radarBalance = '...';
            const provider = new ethers.providers.JsonRpcProvider(configs.FALLBACK_PROVIDER);

            // Interface
            const RadarInterface = new ethers.utils.Interface(
                JSON.parse(JSON.stringify(IERC20ABI))
            );
            
            const Radar = new ethers.Contract(
                configs.CONTRACT_ADDRESSES.RADAR,
                RadarInterface,
                provider
            );
            
            var balance = await Radar.balanceOf(this.state.address);
            var maxValueUnscaled = balance;

            radarBalance = (balance / 10**18);
            if (radarBalance < 0.001) {
                radarBalance = 0;
            }
        } catch(e) {
            console.log(e)
        }

        this.setState({
            maxValue: radarBalance,
            maxValueUnscaled: maxValueUnscaled
        })
    }

    toFixed(x) {
        if (Math.abs(x) < 1.0) {
          var e = parseInt(x.toString().split('e-')[1]);
          if (e) {
              x *= Math.pow(10,e-1);
              x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
          }
        } else {
          var e = parseInt(x.toString().split('+')[1]);
          if (e > 20) {
              e -= 20;
              x /= Math.pow(10,e);
              x += (new Array(e+1)).join('0');
          }
        }
        return x;
      }

    approve = async (e) => {
        e.preventDefault();
        this.setState({
            buttonClicked: true
        })

        if (parseFloat(this.state.value) < 0.0001) {
            return;
        }

        if (this.state.terms === true) {

            this.setState({
                loading: true
            })
    
            try {
                console.log("provider: "+this.state.provider)
                const provider = new ethers.providers.Web3Provider(this.state.provider);
    
                const signer = await provider.getSigner();
    
                // Interface
                const RadarInterface = new ethers.utils.Interface(
                    JSON.parse(JSON.stringify(IERC20ABI))
                );
                const Radar = new ethers.Contract(
                    configs.CONTRACT_ADDRESSES.RADAR,
                    RadarInterface,
                    signer
                );

                var approveAmount = this.state.maxValueSelected ? this.state.maxValueUnscaled : this.toFixed(new BigNumber(this.state.value).multipliedBy(10**18).toString());
                var approveAmount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
                    
                console.log("APPROE AMOUNT: "+approveAmount.toString())
                await Radar.approve(configs.CONTRACT_ADDRESSES.StakingRadar, approveAmount);
            
            } catch (e) {
                console.log(e)
            }
           
            this.setState({
                loading: false,
                approved: true
            })
            this.getAllowance();
        } else {

            return;
        }

    }

    deposit = async (e) => {

        e.preventDefault();

        if (parseFloat(this.state.value) < 0.0001) {
            return;
        }

        this.setState({
            loading: true
        })
        
        try {
            const provider = new ethers.providers.Web3Provider(this.state.provider);

            const signer = await provider.getSigner();

            // Interface
            const StakingRadarInterface = new ethers.utils.Interface(
                JSON.parse(JSON.stringify(StakingRadarABI))
            );
            const StakingRadar = new ethers.Contract(
                configs.CONTRACT_ADDRESSES.StakingRadar,
                StakingRadarInterface,
                signer
            );

            var depositAmount = this.state.maxValueSelected ? this.state.maxValueUnscaled : this.toFixed(new BigNumber(this.state.value).multipliedBy(10**18).toString());

            await StakingRadar.stake(depositAmount);
        
        } catch (e) {
            console.log(e)
        }

        await this.setState({
            loading: false
        })
        this.props.callbackPopups();
    }

    inputField = (e) => {

        this.setState({
            maxValueSelected: false
        })

        if (e.target.value == '') {
            this.setState({ value: '0' });
            return;
        }

        if (e.target.value > this.state.maxValue) {
            this.setState({ maxValueAlert: true });
            return;
        } else {
            
        }

        const re = /^[0-9.\b]+$/;
        if (!re.test(e.target.value)) {
            return;
        }

        var value = e.target.value;
        this.setState({ value: value });
        // this.needsToApprove();
    }

    renderTermsNotTicked() {

        return (

            <>
                <div className="popup-terms-toggle-section">
                    <img
                        onClick={() => this.setState({
                            terms: true
                        })}
                        src={greyCheckboxIcon}
                        alt='not-ticked-checkbox-icon'
                        className="popup-terms-toggle-checkbox-icon"
                    />
                    <div className="popup-terms-info">
                        I agree to the 
                    </div>
                    <a className="popup-terms-link"
                        href="https://radar.global/terms-and-conditions" target="_blank"
                    >
                        Terms & Conditions
                    </a>
                </div>
            </>
        )
    }

    renderTermsTicked() {

        return (

            <>
                <div className="popup-terms-toggle-section">
                    <img
                        onClick={() => this.setState({
                            terms: false
                        })}
                        src={pinkCheckboxIcon}
                        alt='not-ticked-checkbox-icon'
                        className="popup-terms-toggle-checkbox-icon"
                    />
                    <div className="popup-terms-info">
                        I agree to the 
                    </div>
                    <a className="popup-terms-link"
                        href="https://radar.global/terms-and-conditions" target="_blank"
                    >
                        Terms & Conditions
                    </a>
                </div>
            </>
        )
    }

    renderTermsNotTickedAlert() {

        return (

            <>
                <div className="popup-terms-toggle-section">
                    <img
                        onClick={() => this.setState({
                            terms: true
                        })}
                        src={redCheckboxIcon}
                        alt='not-ticked-checkbox-icon'
                        className="popup-terms-toggle-checkbox-icon"
                    />
                    <div className="popup-terms-info">
                        I agree to the 
                    </div>
                    <a className="popup-terms-link"
                        href="https://radar.global/terms-and-conditions" target="_blank"
                    >
                        Terms & Conditions
                    </a>
                </div>
                <div className="popup-terms-alert">
                    You must accept the Terms & Conditions
                </div>
            </>
        )
    }

    renderApproveButton() {

        return (

            <>
                <div className="popup-button"
                    onClick={(e) => this.approve(e)}
                >
                    <div className="popup-button-text">
                        GIVE PERMISSION TO USE RADAR
                    </div>
                </div>
            </>
        )
    }

    renderDepositButton() {

        return (

            <>
                <div onClick={(e) => this.deposit(e)} className="popup-button deposit">
                    <div className="popup-button-text">
                        DEPOSIT
                    </div>
                </div>
            </>
        )
    }

    renderMaxValueAlert() {
        
        return (

            <>
                <div className="popup-wrapper-amount-alert">
                    You have exceeded the amount of tokens in your wallet.
                </div>
            </>
        )
    }

    renderLoading() {

        return (

            <>
                <div className="w-loading-wrapper">
                    <div className="w-loading-content">
                    <Loader
                        type="Puff"
                        color="#E926C3"
                        height={200}
                        width={200}
                        timeout={1000000} //3 secs
                    />
                    </div>
                </div>
            </>
        )
    }

    render() {

        var width = window.innerWidth;

        const mobileInputStyle = {

            width: '100%',
            borderWidth: '0',
            backgroundColor: '#070708',
            color: '#999',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '15px',
            fontWeight: '400',
            outline: 'none',
            textAlign: 'left'
        }

        const webInputStyle = {

            width: '50%',
            borderWidth: '0',
            backgroundColor: '#070708',
            color: '#999',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '15px',
            fontWeight: '400',
            outline: 'none',
            textAlign: 'left'
        }

        return (

            <>
                <div className="popup-wrapper">
                    <div className="popup-box">
                        <div className="popup-header">
                            <div className="popup-header-text">
                                DEPOSIT
                            </div>
                            <div className="popup-header-close-icon-section">
                                <img
                                    onClick={() => this.closePopup()}
                                    src={closeIcon}
                                    alt='close-icon'
                                    className="popup-header-close-icon"
                                />
                            </div>
                        </div>
                        <div className="popup-info">
                            You must allow the application access 
                            to the RADAR token contract 
                            before you can add the radar token 
                            to the staking.
                        </div>
                        <div className="popup-input-section">
                            <div className="popup-input-info">
                                RADAR Amount
                            </div>
                            <div className="popup-input">
                                <input type="text" id="amount" name="amount"
                                    value={this.state.value} onChange={(e) => this.inputField(e)}
                                    style={(width < 1000) ? mobileInputStyle : webInputStyle}
                                />
                                <div className="popup-input-max-amount-bullet"
                                    onClick={() => {
                                        if (this.state.maxValue !== 0)
                                            this.setState({
                                                value: this.state.maxValue,
                                                maxValueAlert: false,
                                                maxValueSelected: true
                                            })
                                    }}
                                >
                                    <div className="popup-input-max-amount">
                                        Max: {this.state.maxValue.toFixed(2)} RADAR
                                    </div>
                                </div>
                            </div>
                            {this.state.maxValueAlert === true && this.renderMaxValueAlert()}
                        </div>
                        {this.state.terms === false && this.state.approved === false && this.state.buttonClicked === false && this.renderTermsNotTicked()}
                        {this.state.terms === false && this.state.approved === false && this.state.buttonClicked === true && this.renderTermsNotTickedAlert()}
                        {this.state.terms === true && this.state.approved === false && this.renderTermsTicked()}
                        {(this.state.allowance < parseInt(this.state.value) * 10**18) && this.renderApproveButton()}
                        {(this.state.allowance >= parseInt(this.state.value) * 10**18) && this.renderDepositButton()}
                    </div>
                </div>
                {this.state.loading === true && this.renderLoading()}
            </>
        )
    }
}

export default DepositPopup;