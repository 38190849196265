import React, { Component } from 'react';

import logo from '../../../assets/logo/logo.png';

import '../styles/header.css';

function replaceRange(s, start, end, substitute) {
    return s.substring(0, start) + substitute + s.substring(end);
}

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.state,
            
            logOutDropDown: false,
        }
    }

    componentDidUpdate(prevPreps) {
        if (this.props.state.walletConnected != this.state.walletConnected ||
            this.props.state.address != this.state.address ||
            this.props.state.balance != this.state.balance ||
            this.props.state.onboard != this.state.onboard) {
            this.setState({
                ...this.props.state
            });
        }
    }

    doConnect = async(e) => {
        e.preventDefault();
        var res = await this.state.onboard.walletSelect();
        if (res) {
            var res2 = await this.state.onboard.walletCheck();
            if (res2) {
                var statee = await this.state.onboard.getState();
                statee.walletConnected = true;
                statee.provider = statee.wallet.provider
                await this.props.connectCallback(statee);
            }
        }
    }

    async doDisconnect(e) {
        e.preventDefault();
        await this.props.updateConnectedStatusCallback(false);
        await this.state.onboard.walletReset();
        var statee = await this.state.onboard.getState();
        statee.walletConnected = false;
        await this.props.connectCallback(statee);
    }
    
    renderAddress() {

        return (

            <>
                <div className="w-header-wallet-connected-section">
                    <div className="w-header-address-box"
                        onClick={() => this.setState({
                            logOutDropDown: true
                        })}
                    >
                        <div className="w-header-address-box-balance-bullet">
                            <div className="w-header-address-box-balance-bullet-text">
                                {parseFloat(this.state.balance / 10**18).toFixed(4)}ETH
                            </div>
                        </div>
                        <div className="w-header-address-box-text">
                            {replaceRange(this.state.address, 6, 38, '...')}
                        </div>
                    </div>
                    <div className="w-header-log-out-button"
                        onClick={(e) => this.doDisconnect(e)}
                    >
                        <div className="w-header-log-out-button-text">
                            Log out
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderMobileAddress() {

        return (

            <>
                <div className="m-header-wallet-connected-section">
                    <div className="m-header-address-box"
                        onClick={() => this.setState({
                            logOutDropDown: true
                        })}
                    >
                        <div className="m-header-address-box-balance-bullet">
                            <div className="m-header-address-box-balance-bullet-text">
                                {parseFloat(this.state.balance / 10**18).toFixed(4)}ETH
                            </div>
                        </div>
                        <div className="m-header-address-box-text">
                            {replaceRange(this.state.address, 6, 38, '...')}
                        </div>
                    </div>
                    <div className="m-header-log-out-button"
                        onClick={(e) => this.doDisconnect(e)}
                    >
                        <div className="m-header-log-out-button-text">
                            Log out
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderConnectWalletButton() {

        return (

            <>
                <div className="w-header-connect-wallet-button"
                    onClick={(e) => this.doConnect(e)}
                >
                    <div className="w-header-connect-wallet-button-text">
                        CONNECT WALLET
                    </div>
                </div>
            </>
        )
    }

    renderMobileConnectWalletButton() {

        return (

            <>
                <div className="m-header-wallet-section">
                    <div className="m-header-connect-wallet-text"
                        onClick={(e) => this.doConnect(e)}
                    >
                        CONNECT WALLET
                    </div>
                </div>
            </>
        )
    }

    render() {

        var width = window.innerWidth;

        if (width > 1000) {
            return (

                <>
                    <div className="w-header-wrapper">
                        <div className="w-header-content">
                            <div className="w-header-logo-section">
                                <img 
                                    src={logo}
                                    alt='radar-staking-logo'
                                    className="w-header-logo"
                                />
                            </div>
                            <div className="w-header-wallet-section">
                                {this.state.walletConnected === false && this.renderConnectWalletButton()}
                                {this.state.walletConnected === true && this.renderAddress()}
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                    
                    <div className="m-header-wrapper">
                        <div className="m-header-content">
                            <div className="m-header-logo-section">
                                <img 
                                    src={logo}
                                    alt='radar-staking-logo'
                                    className="w-header-logo"
                                />
                            </div>
                            <div>
                                {this.state.walletConnected === false && this.renderMobileConnectWalletButton()}
                                {this.state.walletConnected === true && this.renderMobileAddress()}
                            </div>
                        </div>
                    </div>

            )
        }
    }
}

export default Header;
