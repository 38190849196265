import React, { Component } from 'react';

import ProductCard from './sub-components/ProductCard';

import radarProtocolImg from '../../../assets/logo/radar-protocol.png';
import radarWalletImg from '../../../assets/logo/radar-wallet.png';
import radarTerminalImg from '../../../assets/logo/radar-terminal.png';

import '../styles/products.css';

class Products extends Component {

    constructor(props) {
        super(props);

        this.state = {
            radarProtocolTitle: 'Radar Protocol',
            radarProtocolText: 'Autonomous Asset Management is a versatile tool allowing investors and vault managers alike to either create and/or join vaults centered around everything from yield and trading to NFTs.',
            radarProtocolLink: '',

            radarWalletTitle: 'Radar Wallet',
            radarWalletText: 'The Radar mobile app not only allows users to check up on their AAM vaults, they can even interact with them or do swaps through ALA in addition to the built-in mobile browser allowing people to utilize other dapps.',
            radarWalletLink: '',

            radarTerminalTitle: 'Radar Terminal',
            radarTerminalText: 'Autonomous Liquidity Aggregator is a tool that is useful for both inexperienced and pro DeFi users because it allows for cross-chain swaps with low slippage, instant execution and gas optimisations.',
            radarTerminalLink: '',
        }
    }

    render() {

        return (

            <>
                <div className="w-products-wrapper">
                    <div className="w-products-content">
                        <ProductCard
                            titleFromParent={this.state.radarProtocolTitle}
                            imgSrcFromParent={radarProtocolImg}
                            textFromParent={this.state.radarProtocolText}
                            linkFromParent={this.state.radarProtocolLink}
                        />
                        <ProductCard
                        titleFromParent={this.state.radarWalletTitle}
                            imgSrcFromParent={radarWalletImg}
                            textFromParent={this.state.radarWalletText}
                            linkFromParent={this.state.radarWalletLink}
                        />
                        <ProductCard
                            titleFromParent={this.state.radarTerminalTitle}
                            imgSrcFromParent={radarTerminalImg}
                            textFromParent={this.state.radarTerminalText}
                            linkFromParent={this.state.radarTerminalLink}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default Products;