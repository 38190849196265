import React, { Component } from 'react';

import APYCard from '../sub-components/APYCard';
import StakedRadarCard from '../sub-components/StakedRadarCard';
import YourRewardCard from '../sub-components/YourRewardsCard';

import '../styles/cards.css';

class MobileCards extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.state
        }
    }

    componentDidUpdate(prevPreps) {
        if (this.props.state.walletConnected != this.state.walletConnected ||
            this.props.state.address != this.state.address ||
            this.props.state.balance != this.state.balance ||
            this.props.state.onboard != this.state.onboard) {
            this.setState({
                ...this.props.state
            });
        }
    }

    render() {

        return (

            <>
                <div className="m-cards-wrapper">
                    <div className="m-cards-content">
                        <StakedRadarCard {...this.props} 
                            connectCallback={this.props.connectCallback} 
                            state={this.state} {...this.props} 
                        />
                        <YourRewardCard {...this.props} 
                            connectCallback={this.props.connectCallback} 
                            state={this.state} {...this.props} 
                        />
                        <APYCard {...this.props} 
                            connectCallback={this.props.connectCallback} 
                            state={this.state} {...this.props} 
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default MobileCards;
