import React, { Component } from 'react';
import { ethers } from 'ethers'

import { initOnboard } from '../../../ethereum/onboard'
import { abi as StakingRadarABI } from '../../../assets/ABIs/StakingRadar.json';
import { contracts } from '../../../assets/contracts/Contracts';

import configs from './../../../configs';

import icon from '../../../assets/icons/apy-card-icon.png';

import '../styles/cards.css';

class APYCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.state,

            APY: '',
        }
    }

    componentDidUpdate(prevPreps) {
        if (this.props.state.walletConnected != this.state.walletConnected ||
            this.props.state.address != this.state.address ||
            this.props.state.balance != this.state.balance ||
            this.props.state.onboard != this.state.onboard) {
            this.setState({
                ...this.props.state
            });
            this.getAPYData()
        }
    }

    getAPYData = async () => { 
        // Provider
        // var provider = new ethers.providers.Web3Provider(this.state.provider)
        var provider = new ethers.providers.JsonRpcProvider(configs.FALLBACK_PROVIDER)

        // Signer
        // const signer = await provider.getSigner();

        // Nonce
        // const nonce = await provider.getTransactionCount(...this.props.state.address, "pending");

        // Interface
        const StakingRadarInterface = new ethers.utils.Interface(
            JSON.parse(JSON.stringify(StakingRadarABI))
        );
        
        // Contract
        try {
            const stakingRadar = new ethers.Contract(
                configs.CONTRACT_ADDRESSES.StakingRadar,
                StakingRadarInterface,
                provider
              );

            var rewardRate = await stakingRadar.rewardRate();
            var totalSup = await stakingRadar.totalSupply();

            if (totalSup == 0) {
                apy = '-';
            } else {
                var tokensInYear = rewardRate * (365 * 24 * 60 * 60);
                var apy = ((tokensInYear / totalSup) * 100).toFixed(2);
            }
        } catch(error) {
            console.log(error)
        }

        this.setState({APY: apy});
    }

    async componentWillMount() {
        this.getAPYData();
    }

    render() {

        return (

            <>
                <div className="wm-card">
                    <div className="card-content">
                        <div className="card-icon-section">
                            <img 
                                src={icon}
                                alt='apy-card-icon'
                                className="card-icon"
                            />
                        </div>
                        <div className="card-info-section">
                            <div className="card-info-primary-text"
                                style={{ color: Math.sign(this.state.apy) === -1 ? "#8e1600" : "#00AF00" }}
                            >
                                {this.state.APY}%
                            </div>
                            <div className="card-info-secondary-text">
                                APY
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default APYCard;
